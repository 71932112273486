import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore, EntityV2Store } from "../state/CommonState.js"
import { $c, $cr } from '../components/AgComponents.js'
import { useEffect, useRef, useState } from "react"
import Api from "../state/Api.js"
import { MSDS_AND_DG_FILE_MISSING_WARN, Roles, ServerLists } from "../StaticData.js"
import { AgCustomFile } from "../components/AgFile.js"
import BookingsApi from "../state/bookings-api.js"
import { incoTermsList, commodityList, hazardousList } from "../StaticData.js"
import BookingConfirmation from "../schedules/booking-confirmation.js"
import { Agyesno } from "../components/Agyesno.js"
import { AgWarnPopup } from "../components/AgCustomComponents.js"
import BranchApi from "../state/branch-api.js"
import EntityV2Api from "../state/entities-api.js"
import Utils from "../util/Utils.js"
import ShipperApi from "../state/shipper-api.js"
import { CitySelect, StateSelect } from "../entity-v2/components/Country.js"
import LinkEntityPopupv3 from "./linkEntityPopup-v3.js"
import { DummySchedulev3 } from "./dummy-schedule-v3.js"
import LCLScheduleCompv3 from "./LCLScheduleComp-v3.js"
import FCLScheduleCompv3 from "./FCLScheduleComp-v3.js"
import AirScheduleCompv3 from "./AirScheduleComp-v3.js"
import { loader } from "../util/loader.js"
import { SHIPMENT_TYPE } from "../util/constants.js"


export default function CarrierWebFormv3(props) {
    const shipperDefault = { _id: "", firstName: "", lastName: "", emailId: "", mobileNumber: "", companyName: "", address: "", pincode: "", country: "", role: "", city: "", state: "", newShipper: false, linkedEntity: "" }
    let url = window.location.href
    const [space, setSpace] = useState('')
    const [entityData, setentityData] = useState({})
    const [incoEditable, setIncoEditable] = useState(true)
    const [ctypeEditable, setCtypeEditable] = useState(true)
    const [isWarnAccepted, setIsWarnAccepted] = useState(false);
    const [validIncoterms, setValidIncoterms] = useState(true);
    const [branches, setbranches] = useState([]);
    const [validCommodityType, setValidCommodityType] = useState(true);
    const [validbranch, setvalidbranch] = useState(true)
    const [companyName, setCompanyName] = useState('')
    const [validMobile, setValidMobile] = useState(true)
    const [validAddress, setvalidAddress] = useState(true)
    const [addressObj, setaddressObj] = useState({})
    const [validIIPCertificate, setValidIIPCertificate] = useState(true)
    const [validCommercialInvoice, setValidCommercialInvoice] = useState(true)
    const [validPackingList, setValidPackingList] = useState(true)
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [shipperList, setShipperList] = useState([])
    const [shipperSearch, setShipperSearch] = useState('')
    const [buttonClickedAtleastOnce, setbuttonClickedAtleastOnce] = useState(false);
    const [EntityList, setEntityList] = useState([])
    const [showList, setshowList] = useState(false)
    const [cecOc, setCecOc] = useState(null)
    const [cecOd, setCecOd] = useState(null)
    const [newShipper, setNewShipper] = useState(false)
    const [shipperDetails, setShipperDetails] = useState(shipperDefault)
    const searchListRef = useRef(null);
    const [showSearchList, setShowSearchList] = useState(false);
    const [consigneeForm, setConsigneeForm] = useState(true)
    const [milestoneAlert, setMilestoneAlert] = useState(true)


    // Event listener to close search list when clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchListRef.current && !searchListRef.current.contains(event.target)) {
                setShowSearchList(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    let brec = bookingsStore.current
    const aguser = loginStore.userRec.aguserRec
    const [hasToAutoPopulateEntityDetail, setHasToAutoPopulateEntityDetail] = useState(false);

    const filteredShipperList = shipperList?.filter(shipper =>
        shipper.companyName?.toLowerCase().includes(shipperSearch?.toLowerCase())
    );

    useEffect(() => {
        async function setbookingdata() {
            Api.loadCountryList()
            // EntityApi.getList();
            if (contractsStore.current.incoTerms &&
                contractsStore.current.incoTerms.length > 0)
                setIncoEditable(false)
            if (contractsStore.current.commodityType &&
                contractsStore.current.commodityType.length > 0)
                setCtypeEditable(false)
            if (loginStore.isClient()) {
                brec.firstName = aguser.firstName
                brec.lastName = aguser.lastName
                brec.phone = aguser.phone
                brec.emailid = loginStore.email
            }
            brec.incoTerms = contractsStore.current.incoTerms
            brec.commodityType = contractsStore.current.commodityType
            brec.carrierMBL = 'No'
            brec.entityId = brec.contract.entityId
            // if(contractsStore.current.carrierMBL){
            //     brec.carrierMBL = contractsStore.current.carrierMBL
            // }else{
            //     brec.carrierMBL = 'No'
            // }

            // const entity = EntityV2Store.list.find(e1 => {
            //     return e1.entityId === brec.contract.entityId
            // })
            let entity = await EntityV2Api.viewEntity(brec.contract.entityId, setentityData)
            console.log("entity", entity);

            if (!entity) {
                // setCompanyName(brec.contract.gst);
                return;
            }
            let fromLoc = brec.contract.shipmentType.toLowerCase().includes('air') ?
                Utils.extractCodeAndReturnCountry(brec.contract.originAirport)
                : Utils.extractCodeAndReturnCountry(brec.contract.portOfReceipt)
            // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();

            // if (fromLoc.toLowerCase().includes(entity.entityCountry?.toLowerCase())) {
            setHasToAutoPopulateEntityDetail(true);
            brec.company = entity.entityName
            setCompanyName(entity.entityName)
            // brec.branchId=entity.branch[0].branchId
            // brec.address = entity.branch[0].branchAddresses[0].address
            brec.country = entity.branch[0].country
            // brec.pin = entity.branch[0].branchAddresses[0].pincode
            // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
            // }
            // else {
            // setHasToAutoPopulateEntityDetail(true);
            // brec.company = entity.entityName
            // setCompanyName(entity.entityName)
            // brec.branchId=entity.branch[0].branchId
            // brec.address = entity.branch[0].branchAddresses[0].address
            // brec.country = entity.branch[0].country
            // brec.pin = entity.branch[0].branchAddresses[0].pincode
            // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
            // }
            // if(hasToAutoPopulateEntityDetail){
            //     brec.company = brec.contract.gst
            //     console.log('GOT COMPANY AS ========', brec.company, entityStore.list.length)
            //     const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
            //     if (ent.length > 0) {
            //         let entity = ent[0]
            //         console.log('entity found =', entity)
            //         setCompanyName(entity.entityName)
            //         brec.address = entity.address
            //         brec.country = entity.country
            //         brec.pin = entity.pinCode
            //     } else {
            //         setCompanyName(brec.contract.gst)
            //     }
            // }
            console.log('******urgentbooking', brec)
        }
        setbookingdata()

    }, [brec, aguser.firstName, aguser.lastName, aguser.phone])

    useEffect(() => {
        let brec = bookingsStore.current
        let entityId = brec.contract?.entityId
        if (!loginStore.isClient()) {
            BranchApi.getAllBranches(entityId, (data) =>
                setbranches(data)
            )
        } else {
            let entity = loginStore.userRec.companyList.filter((x, i) => x.entityId === configStore.currentSelectedEntity.entityId);
            let branch = entity[0].branches
            setbranches(branch)
        }
        Api.getContract(contractsStore.current.contractRefNum, (data) => {
            brec.premium = data.premium
            brec.sum_insured = data.sum_insured
            brec.insurance_Amount = data.insurance_Amount
        })

    }, [])
    let pincodeRegex;

    if (shipperDetails.country === "India") {
        pincodeRegex = /^\d{0,6}$/;
    } else {
        pincodeRegex = /^\d{0,10}$/;
    }

    const handlePincodeChange = (e) => {
        const { value } = e.target;
        if (pincodeRegex.test(value)) {
            setShipperDetails({ ...shipperDetails, pincode: e.target.value })
        }
    };

    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)

    const setBranchfn = (e) => {
        brec.branchId = e.target.value
        brec.entityId = brec.contract.entityId
        brec.address = ""
        brec.pin = ""
        setaddressObj({})
        setvalidbranch(true)
    }
    const handleSearchChange = (e) => {
        if (e.target.value) { setNewShipper(true) }
        else {
            setNewShipper(false)
            setShipperDetails({ ...shipperDetails, firstName: "", lastName: "", emailId: "", mobileNumber: "", companyName: "", address: "", pincode: "", city: "", state: "", newShipper: false, linkedEntity: "" })
        }
        setShipperSearch(e.target.value);
        setShowSearchList(true);
    };
    const handleEmailChange = ((e) => {
        setShipperDetails({ ...shipperDetails, emailId: e.target.value })
        getEntity(e.target.value)
    }
    )

    const setAddressFn = (r, i) => {
        console.log(r, i);
        if (r.target.value !== "") {
            let data = JSON.parse(r.target.value)
            brec.address = data.address
            brec.pin = data.pincode || data.zipcode
            setaddressObj(r.target.value)
            setvalidAddress(true)
        }

    }
    const getEntity = async (email) => {
        const emailRegex = /^[^\s@]+@(?!agraga\.com$)[^\s@]+\.[^\s@]+$/;
        const emailRegexOnlyAgraga = /^[^\s@]+@agraga\.com$/;

        if (emailRegexOnlyAgraga.test(email)) {
            setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
        }
        if (!emailRegex.test(email)) {
            return;
        }

        try {

            EntityV2Api.GetEntityListWithEmail(email, brec.entityId, (response) => {
                if (response?.data?.length > 0) {
                    setEntityList(response?.data)
                    setshowList(true)
                } else if (response?.errors) {
                    setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
                    configStore.setModalMessage(response?.errors)
                }

            });
        } catch (error) {
            console.log(error);
        }
    }
    const handleEntitySelect = (entityId) => {
        setShipperDetails({ ...shipperDetails, linkedEntity: entityId })
    };


    useEffect(() => {
        brec.originCount = brec.contract.shipmentType.toLowerCase().includes('air') ?
        Utils.extractCodeAndReturnCountry(brec.contract.originAirport)
        : Utils.extractCodeAndReturnCountry(brec.contract.portOfReceipt)
        brec.destinationCount = Utils.extractCodeAndReturnCountry(brec.contract?.finalPlaceOfDelivery)
        setCecOc(brec.country === brec.originCount);
        setCecOd(brec.country === brec.destinationCount);
        setShipperDetails({ ...shipperDetails, country: cecOc ? brec.destinationCount : brec.originCount })
        cecOc && setConsigneeForm(false)
    }, [brec.country, brec.contract.origin, brec.contract.destination, brec.entityId, cecOc, cecOd, brec]);
    useEffect(() => {
        if (shipperDetails.country) {
            const fetchShipperData = async () => {
                try {
                    const res = await ShipperApi.getShipper(brec.entityId, shipperDetails.country);
                    setShipperList(res.data);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchShipperData();
        }
    }, [shipperDetails.country]);

    function checkSelectedBranch() {
        let selectedbranch = entityData.branch.filter((r, i) => r.branchId === brec.branchId)
        return selectedbranch[0]
        //  entityData.branch
    }
    function validEmail(email) {
        // Regular expression pattern for validating email addresses
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }
    const delFile = (a,ind)=>{
        let l = []
        for (let i = 0; i < a.length; i++) {
            if(i!=ind){
                l.push(a[i])
            }
        }
        return l
    }
    const [faberror,setFbaerror] = useState([])
    const onSubmit = () => {
        setButtonClicked(true)
        shipperDetails.companyName = shipperSearch
        shipperDetails.newShipper = newShipper
        if (cecOc){
            shipperDetails.role = "consignee"
            brec.consignee = shipperSearch
            brec.shipper = brec.company
        }else{
            shipperDetails.role = "shipper"
            brec.shipper = shipperSearch
            cecOd?brec.consignee = brec.company: brec.consignee = ""

        }
        setbuttonClickedAtleastOnce(true)
        setButtonClicked(true);
        let hasError = false
        if (((cecOd && !cecOc) || (!cecOd && !cecOc)) && !shipperDetails.companyName) {
            hasError = true
            setButtonClicked(false)
        }
        if (!brec.incoTerms || brec.incoTerms.length < 1) {
            setValidIncoterms(false)
            hasError = true
        } else { setValidIncoterms(true) }
        if (!brec.commodityType || brec.commodityType.length < 1) {
            setValidCommodityType(false)
            hasError = true
        } else { setValidMobile(true) }
        if (!(!cecOc && !cecOd) && (!brec.phone || brec.country !== "India"? (brec.phone.length < 7):(brec.phone.length !== 10))) {
            setValidMobile(false)
            hasError = true
        } else { setValidMobile(true) }
        if (brec.contract.hazardous === 'Yes') {
            if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                setValidIIPCertificate(false)
                hasError = true
            } else {
                setValidIIPCertificate(true)
            }
        }
        if (!brec.branchId || brec.branchId.length < 1) {
            // configStore.setModalMessage('Please select branch address')
            setvalidbranch(false);
            hasError = true

        } else { setvalidbranch(true) }
        if (!brec.address || brec.address.length < 1) {
            // configStore.setModalMessage('Please select branch address')
            setvalidAddress(false);
            hasError = true

        } else { setvalidAddress(true) }
        if (hasError) {
            setButtonClicked(false);
            return
        }
        if (!(!cecOc && !cecOd) && (!brec.firstName || brec.firstName.length < 1)) {
            // configStore.setModalMessage('First Name is Mandatory')
            setButtonClicked(false);
            return
        }
        if (!(!cecOc && !cecOd) && (!brec.emailid || brec.emailid.length < 1 || !validEmail(brec.emailid))) {
            // configStore.setModalMessage('Email is Mandatory')
            setButtonClicked(false);
            return
        }

        if (!(!cecOc && !cecOd) && (!brec.company)
            // || !brec.address || !brec.pin || !brec.country
        ) {
            // configStore.setModalMessage('Please enter all company details')
            // configStore.setModalMessage('Please enter shipper name.')
            setButtonClicked(false)
            return
        }
        if (!brec.country) {
            // configStore.setModalMessage('Please enter all company details')
            // configStore.setModalMessage('Please select country.')
            setButtonClicked(false)
            return
        } if (!brec.pin) {
            // configStore.setModalMessage('Please enter all company details')
            if (entityData.entityCountry.toLowerCase() === 'india') {
                // configStore.setModalMessage('Please Enter PinCode')
            } else {
                // configStore.setModalMessage('Please Enter ZipCode')
            }

            setButtonClicked(false)
            return
        }
        if(brec.fba=='Yes'){
            let e = []
            if(brec?.merchantID?.length>14){
                configStore.setModalMessage(`Merchant ID 14 characters Only.`);
                e.push("merchantID")
                hasError = true;
            }
            for (let i = 0; i < brec?.fbaDetails.length; i++) {
                if(brec?.fbaDetails[i].fbaID?.length>12){
                    configStore.setModalMessage(`FBA ID Maximum 12 characters Only.`);
                    e.push("fbaID"+i)
                    hasError = true;
                }
                if(brec?.fbaDetails[i].purchaseOrderID?.length>8){
                    configStore.setModalMessage(`Amazon Reference ID Maximum 8 characters Only.`);
                    e.push("purchaseOrderID"+i)
                    hasError = true;
                }
            }
            if(JSON.stringify(e)!=JSON.stringify(faberror)){
                setFbaerror(e)
            }
            if(hasError){
                setButtonClicked(false);
                return;
            }
        }
        if(brec.commercialInvoice_filename_list.length>0 && brec.packingList_filename_list.length==0){
            if(validPackingList){
                setValidPackingList(false)
            }
            setButtonClicked(false);
            return;
        }else{
            if(!validPackingList){
                setValidPackingList(true)
            }
        }
        if(brec.commercialInvoice_filename_list.length==0 && brec.packingList_filename_list.length>0){
            if(validCommercialInvoice){
                setValidCommercialInvoice(false)
            }
            setButtonClicked(false);
            return;
        }else{
            if(!validCommercialInvoice){
                setValidCommercialInvoice(true)
            }
        }
        if (shipperDetails.companyName) {
            if (!shipperDetails.address) {
                configStore.setModalMessage(`${shipperDetails.role} Address is required.`);
                hasError = true;
            } else if (!shipperDetails.city) {
                configStore.setModalMessage(` ${shipperDetails.role} City is required.`);
                hasError = true;
            } else if (!shipperDetails.companyName) {
                configStore.setModalMessage(`${shipperDetails.role} Company name is required.`);
                hasError = true;
            } else if (!shipperDetails.country) {
                configStore.setModalMessage(`${shipperDetails.role} Country is required.`);
                hasError = true;
            } else if (!validEmail(shipperDetails.emailId)) {
                configStore.setModalMessage(`A valid ${shipperDetails.role} email is required.`);
                hasError = true;
            } else if (!shipperDetails.firstName) {
                configStore.setModalMessage(`${shipperDetails.role} First name is required.`);
                hasError = true;
            } else if (!shipperDetails.state) {
                configStore.setModalMessage(`${shipperDetails.role} State is required.`);
                hasError = true;
            } else if (!shipperDetails.pincode || shipperDetails.pincode.length < 3 || shipperDetails.pincode.length >= 15 ) {
                configStore.setModalMessage(`${shipperDetails.role} Pincode should be at least 3 digits and to the maximum 15.`);
                hasError = true;
            } else if (shipperDetails.country !== "India" && (shipperDetails.mobileNumber.length < 6 || shipperDetails.mobileNumber.length > 15)) {
                configStore.setModalMessage(`${shipperDetails.role} Mobile number should be between 6 and 15 digits.`);
                hasError = true;
            } else if (shipperDetails.country === "India" && shipperDetails.mobileNumber.length !== 10) {
                configStore.setModalMessage(`In India, ${shipperDetails.role} mobile number should be exactly 10 digits.`);
                hasError = true;
            }
            if (hasError) {
                setButtonClicked(false);
                return;
            }
        }
        brec.sendMilestoneAlert = milestoneAlert
        
        if (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) {
            if ((!brec.msds_filename || brec.msds_filename.length < 1) || (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1)) {
                if (!configStore.hasToShowAgWarn && !isWarnAccepted) {
                    configStore.hasToShowAgWarn = true;
                    setButtonClicked(false);
                    return;
                }
            }
            // if (!brec.msds_filename || brec.msds_filename.length < 1) {
            //     configStore.setModalMessage('MSDS is Mandatory')
            //     return
            // }
            // if (!brec.nondgdecl_filename || brec.nondgdecl_filename.length < 1) {
            //     configStore.setModalMessage('Non-Haz Declaration is Mandatory')
            //     return
            // }
        }

        const callback = (d) =>{
            let toUsers = Utils.getRespectiveMember(EntityV2Store.EntityRec.customer)
            let rec = contractsStore.current
            let CustomerName = EntityV2Store.EntityRec.entityName
            let shipType = rec.shipmentType
            let shipScope = rec.shipmentScope
            let origin = rec.origin
            let destination = rec.destination            
            let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
            let to = toUsers
            if (url.indexOf('booking.agraga.com') >= 0) {
                to = to + ',sanjeev.gupta@agraga.com'
            }
            let subject = 'Booking - ' + configStore.bookingModalNum
            let message = `Hi Team,<br />
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${d._id}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />`
            if(rec.multidest!=undefined && rec.multidest.length>1){
                            for (let i = 0; i < rec.multidest.length; i++) {
                                message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination${i+1}: ${rec.multidest[i]['destination']}.<br />`
                            }
                        }else{
                        message=message+`&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.<br />`
                        }
            message=message+`<br />
            Team Agraga</p>`
            Api.sendClientMailwithoutMessage(from, to, subject, message)
            loader.hide()
        }
        
        let role =[]
        //Ocean
        const entityDetails = contractsStore.current
        if(entityDetails.shipmentType !== 'Air' && cecOc){
            role.push('Ocean export Coordination')
        }
        if(entityDetails.shipmentType !== 'Air' && cecOd){
            role.push('Ocean Import Coordination')
        }
        if(entityDetails.shipmentType !== 'Air' && !cecOc && !cecOd ){
            role.push('Ocean export Coordination')
            role.push('Ocean Import Coordination')
        }
        //Air
        if(entityDetails.shipmentType === 'Air' && cecOc){
            role.push('Air export Coordination')
        }
        if(entityDetails.shipmentType === 'Air' && cecOd){
            role.push('Air Import Coordination')
        }
        if(entityDetails.shipmentType === 'Air' && !cecOc && !cecOd ){
            role.push('Air export Coordination')
            role.push('Air Import Coordination')
        }
        shipperDetails.toUsers =role

        let ccRoleVendor = { ...role }

        if (entityDetails.shipmentType === SHIPMENT_TYPE.lcl)
            ccRoleVendor.push('LCL Procurement')
        else if (entityDetails.shipmentType === SHIPMENT_TYPE.air)
            ccRoleVendor.push('AIR Procurement')
        else
            ccRoleVendor.push('FCL Procurement')

        shipperDetails.ccVendorUsers =ccRoleVendor

        console.log("contractsStore",contractsStore.current)
       
        brec.insurance_Amount_currency_type="USD"
        loader.show()
        BookingsApi.saveBooking(shipperDetails,callback)
    };
    if(contractsStore.current.fba!=undefined && (brec.fba==undefined || contractsStore.current.fba!=brec.fba)){
        brec.fba=contractsStore.current.fba
    }
    return (
        <>
        {
            (entityData!=undefined && entityData.branch!=undefined && entityData.branch.length>0)?
            <div style={{ fontSize: '0.8rem' }}>
                <BookingConfirmation />
                <AgWarnPopup callback={() => {
                    setIsWarnAccepted(true);
                    onSubmit();
                }}>
                    <p className="is-size-7">&emsp;&emsp;{MSDS_AND_DG_FILE_MISSING_WARN}</p>
                </AgWarnPopup>
                {
                    bookingsStore.current?.hasToShowDummySchedule ? <DummySchedulev3 stype={stype()} viewonly={true} />
                        : stype() === 'LCL' ?
                            <LCLScheduleCompv3 data={bookingsStore.current.schedule} viewonly={true} /> :
                            stype() === 'FCL' ?
                                <FCLScheduleCompv3 data={bookingsStore.current.schedule} viewonly={true} /> :
                                <AirScheduleCompv3 />
                }
                <div>
                    <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipper / Exporter details {cecOc && ' - Invoicing'}</h2>
                    <div className="columns" style={{ paddingTop: "10px" }}>
                        <$cr n='2'>Shipper Name</$cr>
                        <$c n='3'>
                            <div className={`select is-small ${(!buttonClickedAtleastOnce || (cecOc ? true : shipperDetails.state)) ? '' : 'is-danger'}`} >
                                <input
                                    type="text"
                                    className={`input is-small${(!buttonClickedAtleastOnce || (cecOc ? true : shipperDetails.state)) ? '' : 'is-danger'}`}
                                    placeholder="Search Shipper"
                                    value={cecOc ? brec.company : shipperSearch}
                                    onChange={handleSearchChange}
                                    disabled={cecOc}
                                    onClick={() => setShowSearchList(true)}
                                />
                                <ul>
                                    {showSearchList && !cecOc && (
                                        <div className="select-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }} ref={searchListRef}>
                                            <ul>
                                                {filteredShipperList?.map((shipper, index) => (
                                                    <li key={index} onClick={() => {
                                                        setNewShipper(false)
                                                        if (cecOc) {
                                                            brec.company = shipper.companyName;
                                                        } else {
                                                            setShipperDetails(shipper);
                                                            setShipperSearch(shipper.companyName);
                                                        }
                                                        setShowSearchList(false);
                                                    }}>
                                                        {shipper.companyName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </$c>
                    </div>
                    {cecOc ?
                        (<div className="columns">
                            <$cr n='2'>Branch *{space}</$cr>
                            <$c n='3'>
                                <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                                    <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e) => setBranchfn(e)}
                                    >
                                        <option value=""  >Select Branch</option>
                                        {
                                            branches?.map((r, i) => {
                                                return (<option value={r.branchId}>{`${r.state?.split("/")[0]} - ${(r.gst.length > 0 ? r.gst : r.tinNum)}`}</option>)
                                            })
                                        }
                                        {/* <option>hyh</option> */}
                                    </select>
                                </div>
                            </$c>

                        </div>) : null
                    }
                    <div className="columns">

                        <$cr n='2'>Address *</$cr>
                        <$c n='3'>
                            {
                                (hasToAutoPopulateEntityDetail && cecOc) ?
                                    <div className={` select wd_100 ${!buttonClickedAtleastOnce || validAddress ? '' : 'is-danger'}`}>
                                        <select className={`wd_100 p-1 select is-fullwidth is-small ${cecOc ? validAddress : true ? '' : 'is-danger'}`} value={addressObj}
                                            onChange={cecOc ? (r, i) => setAddressFn(r, i) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))} disabled={!cecOc && !newShipper}
                                        // brec.pin   = e.target.value 
                                        // console.log(i)
                                        >{<option value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r, i) => <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div> : <textarea className={`textarea wd_100 ${!buttonClickedAtleastOnce || cecOc ? validAddress : shipperDetails.address ? '' : 'is-danger'}`} value={cecOc ? brec.address : shipperDetails.address}
                                            disabled={!cecOc && !newShipper} onChange={cecOc ? ((e) => { brec.address = e.target.value; setvalidAddress(true); }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}
                                    />
                            }
                        </$c>
                    </div>
                    <div className="columns" style={{ paddingTop: "10px" }}>
                        <$cr n='2'>First Name *{space}</$cr>
                        <$c n='3'>
                            <input
                                className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc ? brec.firstName : shipperDetails.firstName)) ? '' : 'is-danger'}`}
                                value={cecOc ? brec.firstName : shipperDetails.firstName} disabled={!cecOc && !newShipper}
                                onChange={cecOc ? ((e) => brec.firstName = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, firstName: e.target.value }))}
                            />
                        </$c>
                        <$cr n='2'>Last Name </$cr>
                        <$c n='3'>
                            <input
                                className={`input is-small`}
                                value={cecOc ? brec.lastName : shipperDetails.lastName} disabled={!cecOc && !newShipper}
                                onChange={cecOc ? ((e) => brec.lastName = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, lastName: e.target.value }))}
                            />
                        </$c>
                    </div>
                    <div className="columns">
                        <$cr n='2'>Email ID *</$cr>
                        <$c n='3'>
                            <input className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc ? validEmail(brec.emailid) : shipperDetails.emailId)) ? '' : 'is-danger'}`} disabled={!cecOc && !newShipper} value={cecOc ? brec.emailid : shipperDetails.emailId}
                                onChange={cecOc ? ((e) => brec.emailid = e.target.value) : handleEmailChange}
                            />
                        </$c>
                        <$cr n='2'>Contact Number *</$cr>
                        <$c n='3'>
                            <input
                                className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc ? (brec.country !== "India"?(brec.phone.length > 6):(brec.phone.length === 10)) : shipperDetails.mobileNumber.length > 6)) ? '' : 'is-danger'}`}
                                value={cecOc ? brec.phone : shipperDetails.mobileNumber}
                                onChange={cecOc ? ((e) => brec.phone = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, mobileNumber: e.target.value }))}
                                min="10"
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                disabled={!cecOc && !newShipper}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                                }}
                            />
                        </$c>

                    </div>

                    {!cecOc ?
                    <div>
                    <div className="columns">
                     <$cr n='2'>Send Milestone alerts ?</$cr>
                        <$c n='3'>
                            <input className={`checkbox`} type = 'checkbox' checked={milestoneAlert}
                               onChange={() => setMilestoneAlert(!milestoneAlert)} 
                            />
                        </$c>
                    </div>
                     <div className="columns">

                        <$cr n='2'>State *</$cr>
                        <$c n='3'><StateSelect label={true} disabled={!cecOc && !newShipper} countryValue={shipperDetails.country} value={shipperDetails.state} callback={(e) => {
                            setShipperDetails(prevShipperDetails => ({
                                ...prevShipperDetails,
                                state: e?.name,
                                city: ""
                            }));

                        }}></StateSelect></$c>
                         {shipperDetails.country !== "India" ? (
                            <>
                                <$cr n='2'>City</$cr>
                                <$c n='3'>
                                    <input
                                    className="input is-small"
                                        value={shipperDetails.city}
                                        disabled={!cecOc && !newShipper}
                                        onChange={((e) => setShipperDetails({ ...shipperDetails, city: e.target.value }))}
                                    />
                                </$c>
                            </>
                        ) : (
                            <>
                            <$cr n='2'>City *</$cr>  
                            <$c n='3'><CitySelect label={true} disabled={!cecOc && !newShipper} stateValue={shipperDetails.state} value={shipperDetails.city} callback={(e) => {
                                setShipperDetails(prevShipperDetails => ({
                                    ...prevShipperDetails,
                                    city: e?.name
                                }))

                            }}/></$c>
                            </>
                        )}
                    </div> </div> : null
                    }
                    <div className="columns is-narrow">
                        <$cr n='2'>PIN/ZIP Code *</$cr>
                        <$c n='3'>
                            {
                                hasToAutoPopulateEntityDetail && cecOc ? brec.pin
                                    : <input className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc ? true : shipperDetails.pincode)) ? '' : 'is-danger'}`} disabled={!cecOc && !newShipper} type="number" value={cecOc ? brec.pin : shipperDetails.pincode} onKeyDown={(e) => {
                                        if (e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                        onChange={cecOc ? ((e) => brec.pin = e.target.value) : handlePincodeChange}
                                    />
                            }
                        </$c>
                        <$cr n='2'>Country</$cr>
                        <$c n='3'>
                            {
                                hasToAutoPopulateEntityDetail && cecOc
                                    ? brec.country
                                    : shipperDetails.country
                            }
                        </$c>
                    </div>
                </div>
                {!(!cecOc && !cecOd) ?
                    <div>
                        { cecOc && !cecOd?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <$cr n='2'>
                         <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Do you want to add consignee details?</h2></$cr>
                         <$c n='3'><Agyesno label={''} record={''} name={''} callback={(val)=>{
                            setConsigneeForm(!consigneeForm)
                         }}/></$c>
                </div>:null
}
                        {consigneeForm && <div>
                        <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Consignee / Importer details {cecOd && ' - Invoicing'}</h2>
                        <div className="columns" style={{ paddingTop: "10px" }}>
                            <$cr n='2'>Consignee Name</$cr>
                            <$c n='3'>
                                <div className="select is-small">
                                    <input
                                        type="text"
                                        className="input is-small"
                                        placeholder="Search Consignee"
                                        value={cecOd ? brec.company : shipperSearch}
                                        onChange={handleSearchChange}
                                        disabled={cecOd}
                                        onClick={() => setShowSearchList(true)}
                                    />
                                    <ul>
                                        {showSearchList && !cecOd && (
                                            <div className="select-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }} ref={searchListRef}>
                                                <ul>
                                                    {filteredShipperList?.map((shipper, index) => (
                                                        <li key={index} onClick={() => {
                                                            setNewShipper(false)
                                                            if (cecOd) {
                                                                brec.company = shipper.companyName;
                                                            } else {
                                                                setShipperDetails(shipper);
                                                                setShipperSearch(shipper.companyName);
                                                            }
                                                            setShowSearchList(false); // Close search list on click
                                                        }}>
                                                            {shipper.companyName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </ul>

                                </div>
                            </$c>
                        </div>


                        {cecOd ?
                            (<div className="columns">
                                <$cr n='2'>Branch *{space}</$cr>
                                <$c n='3'>
                                    <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                                        <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e) => setBranchfn(e)}
                                        >
                                            <option value=""  >Select Branch</option>
                                            {
                                                branches?.map((r, i) => {
                                                    return (<option value={r.branchId}>{`${r.state?.split("/")[0]} - ${(r.gst.length > 0 ? r.gst : r.tinNum)}`}</option>)
                                                })
                                            }
                                            {/* <option>hyh</option> */}
                                        </select>
                                    </div>
                                </$c>
                            </div>) : null}
                        <div className="columns">
                            <$cr n='2'>Address {cecOd && '*'}</$cr>
                            <$c n='3'>
                                {
                                    (hasToAutoPopulateEntityDetail && cecOd) ?
                                        <div className={` select wd_100 ${!buttonClickedAtleastOnce || validAddress ? '' : 'is-danger'}`}>
                                            <select className={`wd_100 p-1 select is-fullwidth is-small ${cecOd ? validAddress : true ? '' : 'is-danger'}`} value={addressObj}
                                                onChange={cecOd ? (r, i) => setAddressFn(r, i) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))} disabled={!cecOd && !newShipper}
                                            // brec.pin   = e.target.value 
                                            // console.log(i)
                                            >{<option value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r, i) => <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div> : <textarea className={`textarea wd_100 ${!buttonClickedAtleastOnce || cecOd ? validAddress : true ? '' : 'is-danger'}`} value={cecOd ? brec.address : shipperDetails.address}
                                                disabled={!cecOd && !newShipper} onChange={cecOd ? ((e) => { brec.address = e.target.value; setvalidAddress(true); }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}
                                        />
                                }
                            </$c>
                        </div>
                        <div className="columns" style={{ paddingTop: "10px" }}>
                            <$cr n='2'>First Name {cecOd && '*'}{space}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ? brec.firstName : true)) ? '' : 'is-danger'}`}
                                    value={cecOd ? brec.firstName : shipperDetails.firstName} disabled={!cecOd && !newShipper}
                                    onChange={((e) => cecOd ? (brec.firstName = e.target.value) : setShipperDetails({ ...shipperDetails, firstName: e.target.value }))}
                                />
                            </$c>
                            <$cr n='2'>Last Name{cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small`}
                                    value={cecOd ? brec.lastName : shipperDetails.lastName} disabled={!cecOd && !newShipper}
                                    onChange={((e) => cecOd ? (brec.lastName = e.target.value) : setShipperDetails({ ...shipperDetails, lastName: e.target.value }))}
                                />
                            </$c>
                        </div>
                        <div className="columns">
                            <$cr n='2'>Email ID {cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ? validEmail(brec.emailid) : true)) ? '' : 'is-danger'}`} disabled={!cecOd && !newShipper} value={cecOd ? brec.emailid : shipperDetails.emailId}
                                    onChange={cecOd ? ((e) => brec.emailid = e.target.value) : handleEmailChange}
                                />
                            </$c>
                            <$cr n='2'>Contact Number {cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ?  (brec.country !== "India"?(brec.phone.length > 6):(brec.phone.length === 10)) : true)) ? '' : 'is-danger'}`}
                                    value={cecOd ? brec.phone : shipperDetails.mobileNumber} disabled={!cecOd && !newShipper}
                                    onChange={cecOd ? ((e) => brec.phone = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, mobileNumber: e.target.value }))}
                                    min="10"
                                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                                    }}
                                />
                            </$c>

                        </div>
                        {!cecOd ? <div>
                            <div className="columns">
                    <$cr n='2'>Send Milestone alerts ?</$cr>
                        <$c n='3'>
                            <input className={`checkbox`} type = 'checkbox' checked={milestoneAlert}
                                onChange={() => setMilestoneAlert(!milestoneAlert)} 
                            />
                        </$c>
                    </div><div className="columns">
                            <$cr n='2'>State *</$cr>
                            <$c n='3'><StateSelect label={true} disabled={!cecOd && !newShipper} countryValue={shipperDetails.country} value={shipperDetails.state} callback={(e) => {
                                setShipperDetails(prevShipperDetails => ({
                                    ...prevShipperDetails,
                                    state: e?.name,
                                    city: ""
                                }));

                            }}></StateSelect></$c>
                            {shipperDetails.country !== "India" ? (
                                <>
                                    <$cr n='2'>City</$cr>
                                    <$c n='3'>
                                        <input
                                        className="input is-small"
                                            value={shipperDetails.city}
                                            disabled={!cecOd && !newShipper}
                                            onChange={((e) => setShipperDetails({ ...shipperDetails, city: e.target.value }))}
                                        />
                                    </$c>
                                </>
                            ) : (
                                <>
                                <$cr n='2'>City *</$cr>  
                                <$c n='3'><CitySelect label={true} disabled={!cecOd && !newShipper} stateValue={shipperDetails.state} value={shipperDetails.city} callback={(e) => {
                                    setShipperDetails(prevShipperDetails => ({
                                        ...prevShipperDetails,
                                        city: e?.name
                                    }));

                                }}/></$c>
                                </>
                            )}
                        </div></div> : null
                        }
                        <div className="columns is-narrow">
                            <$cr n='2'>PIN/ZIP Code</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail && cecOd ? brec.pin
                                        : <input className={`input is-small`} disabled={!cecOd && !newShipper} type="number" value={cecOd ? brec.pin : shipperDetails.pincode} onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                            onChange={cecOd ? ((e) => brec.pin = e.target.value) : handlePincodeChange}
                                        />
                                }
                            </$c>
                            <$cr n='2'>Country</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail && cecOd
                                        ? brec.country
                                        : brec.destinationCount
                                }
                            </$c>
                        </div>
                        </div>}
                    </div> : null
                }


                {!cecOc && !cecOd ?
                    (<div><h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Invoice Details</h2><div className="columns">
                        <$cr n='2'>Branch *{space}</$cr>
                        <$c n='3'>
                            <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                                <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e) => setBranchfn(e)}
                                >
                                    <option value=""  >Select Branch</option>
                                    {
                                        branches?.map((r, i) => {
                                            return (<option value={r.branchId}>{`${r.state?.split("/")[0]} - ${(r.gst.length > 0 ? r.gst : r.tinNum)}`}</option>)
                                        })
                                    }
                                    {/* <option>hyh</option> */}
                                </select>
                            </div>
                        </$c>
                        <$cr n='2'>Address *</$cr>
                        <$c n='3'>
                            {
                                (hasToAutoPopulateEntityDetail) ?
                                    <div className={` select wd_100 ${validAddress ? '' : 'is-danger'}`}>
                                        <select className={`wd_100 p-1 select is-fullwidth is-small ${validAddress ? '' : 'is-danger'}`} value={addressObj}
                                            onChange={(r, i) => setAddressFn(r, i)}
                                        >{<option value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r, i) => <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div> : <textarea className={`textarea wd_100 ${validAddress ? '' : 'is-danger'}`} value={brec.address}
                                            onChange={cecOd ? ((e) => { brec.address = e.target.value; setvalidAddress(true); }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}
                                    />

                            }
                        </$c>
                    </div><div className="columns">
                            <$cr n='2'>PIN/ZIP Code</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail ? brec.pin
                                        : <input className={`input is-small`} type="number" value={brec.pin} onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                            onChange={(e) => brec.pin = e.target.value}
                                        />
                                }
                            </$c>
                            <$cr n='2'>Country</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail
                                        ? brec.country
                                        : <div class="control">
                                            <div class={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`}>
                                                <select className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`} value={brec.country}
                                                    onChange={(e) => brec.country = e.target.value}
                                                >
                                                    {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                }
                            </$c>

                        </div></div>
                    ) : null
                }
            <div>
            <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipment details</h2>

                <div className="columns">
                    <$cr n='2'>Incoterms *</$cr>
                    <$c n='3'>
                        {
                            (incoEditable) ? (
                                <div className="control">
                                    <div className={`select ${validIncoterms ? '' : 'is-danger'}`}>
                                        <select
                                            className={`select is-fullwidth is-small ${validIncoterms ? '' : 'is-danger'}`}
                                            value={brec.incoTerms}
                                            onChange={(e) => {
                                                brec.incoTerms = e.target.value;
                                                setValidIncoterms(true)
                                            }}
                                        >
                                            {incoTermsList.map((e) => (
                                                <option key={e} value={e}>
                                                    {e}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ) : (
                                <span style={{ color: validIncoterms ? '' : 'red' }}>{brec.incoTerms}</span>
                            )
                        }

                    </$c>
                    <$cr n='2'>Commodity Type *</$cr>
                    <$c n='3'>
                        {
                            (ctypeEditable) ? (
                                <div className="control">
                                    <div className={`select ${validCommodityType ? '' : 'is-danger'}`}>
                                        <select
                                            className={`select is-fullwidth is-small ${validCommodityType ? '' : 'is-danger'}`}
                                            value={brec.commodityType}
                                            onChange={(e) => {
                                                brec.commodityType = e.target.value;
                                                setValidCommodityType(true)
                                            }}
                                        >
                                            {commodityList.map((e) => (
                                                <option key={e} value={e}>
                                                    {e}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ) : (
                                <span style={{ color: validCommodityType ? '' : 'red' }}>{brec.commodityType}</span>
                            )
                        }

                    </$c>
                </div>
                {/* <div className="columns is-narrow">      
                <$cr n='2'>FBA</$cr>       
                <$c n='2'>
                    {
                        (contractsStore.current.fba!=undefined)?<Agyesno disabled={true} label='' record={brec} name='fba' />:
                        <Agyesno label='' record={brec} name='fba' />
                    }
                </$c>
                <$c n='8'>
                </$c>
            </div> */}
            {
                (brec.fba=='Yes')? <>
                <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>FBA Details</h2>
                <div className="columns is-narrow">    
                        <$cr n='2'>Merchant ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small ${faberror.indexOf("merchantID")!=-1?"is-danger":""}`} value={brec.merchantID}
                                onChange={(e)=>{
                                    brec.merchantID=Utils.removeSpecialCharacters(e.target.value.trim(),14)
                                }}
                            />
                        </$c>
                    </div>
                    {
                        brec.fbaDetails.map((e,i)=>{
                            return <>
                            {
                                brec.fbaDetails.length>1?<div className="columns is-narrow">    
                                <$cr n='2'><h2 style={{ paddingTop: "10px", fontSize: "0.7rem", fontWeight: "bold" }}>Destination {i+1}</h2></$cr>
                            </div>:""
                            }
                            <div className="columns is-narrow">   
                        <$cr n='2'>FBA ID</$cr>
                        <$c n='3'>
                            <p class="control has-icons-left">
                                <input class="input is-small" className={`input is-small ${faberror.indexOf("fbaID"+i)!=-1?"is-danger":""}`} value={brec.fbaDetails[i].fbaID}
                                    onChange={(e)=>{
                                        brec.fbaDetails[i].fbaID=Utils.removeSpecialCharacters(e.target.value.trim(),12)
                                    }}
                                />
                                <span class="icon is-small is-left">
                                FBA
                                </span>
                            </p>
                        </$c>
                        <$cr n='2'>Amazon Reference ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small ${faberror.indexOf("purchaseOrderID"+i)!=-1?"is-danger":""}`} value={brec.fbaDetails[i].purchaseOrderID}
                                onChange={(e)=>{
                                    brec.fbaDetails[i].purchaseOrderID=Utils.removeSpecialCharacters(e.target.value.trim(),8)
                                }}
                            />
                        </$c>
                        <$cr n='2'></$cr>
                        <$c n='3'>
                        </$c>
                    </div>
                            </>
                        })
                    }
                </>:<></>
            }
            
                <div className="columns is-narrow">
                    <$cr n='2'>Special Instructions</$cr>
                    <$c n='6'>
                        <textarea rows='3' style={{ fontSize: '0.8rem' }} className="textarea" value={brec.specialInstructions}
                            onChange={(e) => brec.specialInstructions = e.target.value} />
                    </$c>
                    <$c n='2'>
                        {
                            // (enableMBL().disabled )
                            (contractsStore.current.shipmentType.toLowerCase().includes('fcl')
                                && contractsStore.current.shipmentScope.endsWith('ort')) ?
                                <Agyesno label='Do you require a Direct Carrier MBL?'
                                    record={brec} name='carrierMBL' callback={val => brec.carrierMBL = val} /> : null
                        }
                    </$c>
                </div>
                </div>

                <div className="columns">
                    <$c n='1'></$c>
                    <$c n='9'>
                        <div className="table-container ">
                            <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                                <thead>
                                    <tr>
                                        <th style={{ width: '16rem' }}>Required Documents</th>
                                        <th>Document Uploaded</th>
                                        <th style={{ width: '12rem' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr id="cwf5">
                                        <td>Commercial Invoice (Non-Mandatory)</td>
                                        <td className={validCommercialInvoice ? '' : 'is-danger-border'}>
                                            {brec.commercialInvoice_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                    <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.commercialInvoice_filename_list = delFile(brec.commercialInvoice_filename_list,i)
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span></p><br /></>)}
                                        </td>
                                        <td>
                                            <AgCustomFile source='carrierwebform' cat={filecat} filetype='commercialInvoice' parent="cwf5" multi={true}
                                                callback={(filename) => brec.commercialInvoice_filename_list.push(filename)} withBtn={brec.commercialInvoice_filename_list.length > 0} />
                                        </td>
                                    </tr>
                                    <tr id="cwf6">
                                        <td>Packing List (Non-Mandatory)</td>
                                        <td className={validPackingList ? '' : 'is-danger-border'}>{brec.packingList_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                    <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.packingList_filename_list = delFile(brec.packingList_filename_list,i)
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span></p><br /></>)}</td>
                                        <td>
                                            <AgCustomFile source='carrierwebform' cat={filecat} filetype='packingList' parent="cwf6" multi={true}
                                                callback={(filename) => brec.packingList_filename_list.push(filename)} withBtn={brec.packingList_filename_list.length > 0} />
                                        </td>
                                    </tr>
                                    <tr id="cwf7">
                                        <td>Special Instructions (Non-Mandatory)</td>
                                        <td>
                                            {brec.specialInstructions_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                    <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.specialInstructions_filename_list = delFile(brec.specialInstructions_filename_list,i)
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span></p><br /></>)}
                                        </td>
                                        <td>
                                            <AgCustomFile source='carrierwebform' cat={filecat} filetype='specialInstructions' parent="cwf7" multi={true}
                                                callback={(filename) => brec.specialInstructions_filename_list.push(filename)} withBtn={brec.specialInstructions_filename_list.length > 0} />
                                        </td>
                                    </tr>
                                    {
                                        (
                                            (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                            || (brec.contract.hazardous === 'Yes')
                                        ) ?
                                            <tr id="cwf1">
                                                <td>MSDS</td>
                                                <td><p>{renderLink(brec.msds_filename)}
                                                    { brec.msds_filename!=undefined && brec.msds_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.msds_filename = ""
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span>}</p></td>
                                                <td>
                                                    <AgCustomFile source='carrierwebform' cat={filecat} filetype='msds' multi={false}
                                                        callback={(filename) => brec.msds_filename = filename} parent="cwf1" />
                                                </td>
                                            </tr>
                                            : <></>
                                    }
                                    {
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                            <tr id="cwf2">
                                                <td>Non-DG Declaration <a className="is-link" href="./Non_Hazardous_Declaration.doc" download>(Download Template)</a></td>
                                                <td><p>{renderLink(brec.nondgdecl_filename)}
                                                    { brec.nondgdecl_filename!=undefined && brec.nondgdecl_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.nondgdecl_filename = ""
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span>}</p></td>
                                                <td>
                                                    <AgCustomFile cat={filecat} filetype='nondgdecl' multi={false}
                                                        callback={(filename) => brec.nondgdecl_filename = filename} parent="cwf2" />
                                                </td>
                                            </tr> : <></>
                                    }
                                    {
                                        (brec.contract.hazardous === 'Yes') ?
                                            <>
                                                <tr id="cwf3">
                                                    <td>DG Declaration <a className="is-link" href="./DG_Declaration_updated.doc" download>(Download Template)</a></td>
                                                    <td><p>{renderLink(brec.dgdecl_filename)}
                                                    { brec.dgdecl_filename!=undefined && brec.dgdecl_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.dgdecl_filename = ""
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span>}</p></td>
                                                    <td>
                                                        <AgCustomFile cat={filecat} filetype='dgdecl' multi={false}
                                                            callback={(filename) => brec.dgdecl_filename = filename} parent="cwf3" />
                                                    </td>
                                                </tr>
                                                <tr id="cwf4">
                                                    <td >IIP Certificate *</td>
                                                    <td className={validIIPCertificate ? '' : 'is-danger-border'}><p>{renderLink(brec.iipcert_filename)}
                                                    { brec.iipcert_filename!=undefined && brec.iipcert_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        brec.iipcert_filename = ""
                                                    }}>
                                                        <i className="fas fa-trash"></i>
                                                    </span>}</p></td>
                                                    <td   >
                                                        <AgCustomFile
                                                            cat={filecat}
                                                            filetype='iipcert'
                                                            multi={false}
                                                            callback={(filename) => brec.iipcert_filename = filename}
                                                            parent="cwf4"
                                                        />
                                                    </td>
                                                </tr>

                                            </> : <></>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </$c>
                </div>
                <div className="columns">
                    <$cr n='1'></$cr>
                    <$c n='9'>
                        <button className="button is-warning" onClick={(e) => {
                            configStore.scheduleOrCarrierform = 'schedule'
                        }}>
                            <span className="icon" style={{ marginRight: '5px' }}>
                                <i class="fa-solid fa-circle-left"></i>
                            </span>
                            Schedules
                        </button>
                        <div class="buttons" style={{ float: 'right' }}>
                            {/* <button className="button is-danger is-right" onClick={(e) => {
                            bookingsStore.current = {};
                            configStore.homescreenCurrentView = 'list'
                        }} > Cancel </button> */}
                            <button className="button is-link" onClick={onSubmit}
                             disabled={isButtonClicked}
                            >
                                Confirm and Save Booking
                            </button>
                        </div>
                    </$c>
                </div>
                <LinkEntityPopupv3 show={showList} onClose={() => setshowList(false)} entityList={EntityList} onEntitySelect={handleEntitySelect} entityId ={shipperDetails.linkedEntity}  />
            </div>:""
        }
        </>
    )
}
